<template>
   <div class="cooperation-warp">
      <erji-carousel></erji-carousel>
      <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 产业发展整体规划 ——</p>
            </div>
       </div>
       <div class="yz-img-con">
         <img src="../../../assets/images/dev01.png">
       </div>
      <!-- 发展项目-->
       <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 产业发展项目 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p></p>
            </div>
       </div>
       <div class="yz-gov-con">
         <div class="gov-item">
            <p class="item-img"><img src="../../../assets/images/de01.png" ></p>
            <hr class="fenge">
             <p class="desc-gov">视频平台、视频网站 直播平台、社交平台</p>
            <img src="../../../assets/images/de10.png" class="imgs">
            <p class="title-gov">数据标注服务业务</p>
         </div>
         <div class="gov-item">
            <p class="item-img"><img src="../../../assets/images/de02.png" ></p>
            <hr class="fenge">
            <p class="desc-gov">朝阳项目，多样化培训-电气、暖通、监控、消防、自动化工具、管理流程等</p>
            <img src="../../../assets/images/de11.png" class="imgs">
            <p class="title-gov">数据运维助理工程师订单班</p>
         </div>
         <div class="gov-item">
            <p class="item-img"><img src="../../../assets/images/de03.png" ></p>
            <hr class="fenge">
            <p class="desc-gov">教育部工业机器人 ”1+X证书“省级管理中心</p>
            <img src="../../../assets/images/de12.png" class="imgs">
            <p class="title-gov">教育部“1+X”证书项目</p>
         </div>
       </div>
       <!-- 产业基地建设-->
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 产业基地建设 ——</p>
            </div>
       </div>
       <div class="yz-img-con">
         <img src="../../../assets/images/build01.png">
       </div>
   </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
.ab-text{
    margin:30px  0px;
    .ab-title{
       text-align: center;
       font-size: 30px;
       color:#0184d2;
    }
  .ab-desc{
    margin-top:10px;
    text-align: center;
    font-size: 14px;
    color: #656d78;
  }
}
   .yz-img-con{
       width: 100%;
       background: url("../../../assets/images/AI-back.png");
     img{
       width: 100%;
       object-fit:none;
       display: block;
       margin: 0 auto;
       height: 100%;
     }
   }
   .cooperation-warp{
     .yz-gov-con{
       width:1200px;
       margin:0 auto;
       display: flex;
        justify-content: center;/*水平居中*/
		    align-items: center;/*垂直居中*/
       .gov-item{
         width: 25%;
         height:460px;
         margin:40px;
         background:#F7F7F7;
         padding:20px;
         //img{
         //  display: inline-block;
         //}
         .item-img{
           height: 65px;
         }
         .imgs{
           width: 100%;
           height: 195px;
           padding:10px 0px;
         }
         .title-gov{
           font-size:20px;
           color: #148EFF;
           margin-top:36px;
         }
         .desc-gov{
           font-size: 16px;
           line-height: 30px;
           height: 70px;
         }
         .fenge{
           width: 104px;
           display:inline-block;
           height: 3px;
           background: #148EFF;
           text-align: center;
           margin:20px 0px;
         }
       }
     }
     .hezuo-con {
        h2{
          font-size: 20px;
          font-weight: bold;
        }
       .hezuo-fenge{
           width: 38px;
           display:inline-block;
           height: 3px;
           background: #148EFF;
           text-align: center;
           margin:20px 0px;
       }
       .hezuo-item {
         width: 1200px;
         margin: 0 auto;
         height: 344px;
         background: #F7F7F7;

         img {
           width: auto;
           margin-top: 40px;
         }
       }
     }
   }
</style>
